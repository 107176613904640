import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Link from 'ui-kit/link/link';

import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

import { getPhoneNumber } from 'util/globalVariables';

import useResetToken, { flowTypes } from 'hooks/useResetToken';

import './verification-failed.style.scss';

const VerificationFailed = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.VerificationFailedDataQuery;
}) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');

    useResetToken(flowParameter as flowTypes);

    const getButtonLabel = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'auto-refill':
                return t('pages.verificationFailed.buttonLabelAutoRefill');
            case 'easy-refill':
                return t('pages.verificationFailed.buttonLabelEasyRefill');
            case 'caregiver-access':
                return t('pages.verificationFailed.buttonLabelCaregiverAccess');
            case 'join-membership-plan':
                return t('pages.verificationFailed.buttonLabelJoinMembershipPlan');
            default:
                return t('pages.verificationFailed.buttonLabel');
        }
    };

    const getButtonTo = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'join-membership-plan':
                return '/';
            default:
                return '/sign-in';
        }
    };

    return (
        <ErrorScreenLayout title={t('pages.verificationFailed.title')}>
            <Container className="verification-failed">
                <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        {(flowParameter && flowParameter === 'easy-refill') || flowParameter === 'auto-refill' ? (
                            <>
                                <p className="mobile-auto-refill-subtitle1">
                                    {t('pages.verificationFailed.autoRefillSubtitle.paragraphOne')}
                                </p>

                                <p
                                    className="mobile-auto-refill-subtitle2"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.verificationFailed.autoRefillSubtitle.paragraphTwo') +
                                                getPhoneNumber({ isEnd: true })
                                        )
                                    }}
                                />
                            </>
                        ) : flowParameter === 'caregiver-access' ? (
                            <>
                                <p>{t('pages.verificationFailed.caregiverAccessSubtitle.paragraphOne')}</p>

                                <p
                                    className="caregiver-access-subtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.verificationFailed.caregiverAccessSubtitle.paragraphTwo') +
                                                getPhoneNumber({ isEnd: true })
                                        )
                                    }}
                                />
                            </>
                        ) : flowParameter === 'join-membership-plan' ? (
                            <>
                                <p>{t('pages.verificationFailed.joinMembershipPlanSubtitle.paragraphOne')}</p>

                                <p
                                    className="caregiver-access-subtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.verificationFailed.joinMembershipPlanSubtitle.paragraphTwo') +
                                                getPhoneNumber({ isEnd: true })
                                        )
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <p
                                    className="mobile-auto-refill-subtitle1"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            t('pages.verificationFailed.subtitle.paragraphOne') +
                                                getPhoneNumber({ isEnd: true })
                                        )
                                    }}
                                />

                                <p className="mobile-auto-refill-subtitle2">
                                    {t('pages.verificationFailed.subtitle.paragraphTwo')}
                                </p>
                            </>
                        )}

                        <Link
                            to={getButtonTo(flowParameter)}
                            label={getButtonLabel(flowParameter)}
                            dataGALocation="VerificationFailed-Signin"
                            ariaLabel="VerificationFailed-Signin"
                            variant="cta-button"
                        />
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default VerificationFailed;

export const query = graphql`
    query VerificationFailedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
